<template>
    <!-- v-if="returnAbility('backup:run')"  -->
    <div>
        <v-row>
            <v-col md="3">
                <v-btn style="margin: 30px;" color="green" dark @click="resultsStructure" >
                   
                   {{ $t('Results Structure') }}
               </v-btn>
            </v-col>
         
            <v-col md="3" v-if="returnAbility('final_grade:index')">
                <v-btn style="margin: 30px;" color="green" dark @click="resultsTemplate" >
                   
                   {{ $t('Result Template') }}
               </v-btn>
            </v-col>
   

        </v-row>
      
    </div>
</template>
<script>

export default {
    components: {

    },
    data() {
        return {
           
        };
    },
    methods: {
         resultsStructure() {
              this.$router.push("/resultsStructure")
        },
        resultsTemplate() {
            this.$router.push("/resultsTemplate");
        }
 
    },
    computed: {
      
    },
    created() {
    }
};
</script>